<template>
  <section>
    <div class="row justify-content-center mb-4">
      <div class="col-11">
        <img src="@/assets/images/banner.png" alt="Banner" class="img-fluid w-100"/>
      </div>
    </div>
    <div class="row justify-content-center mb-5 text-light">
      <div class="col-7">
        <h6 class="text-shadow mb-0"><strong>Venue</strong></h6>
        <p class="text-shadow-sm h6">
          Nawasena<br />
          Kawasan Bank Mandiri Wijayakusuma
        </p>
      </div>
      <div class="col-4">
        <h6 class="text-shadow mb-0"><strong>Kamis</strong></h6>
        <p class="text-shadow-sm h6">14 Desember 2023</p>
      </div>
    </div>

    <CountdownBase/>

    <div class="row justify-content-center mb-4">
      <div class="col-11 video-frame">
        <div class="embed-responsive embed-responsive-16by9">
          <video ref="video" class="embed-responsive-item" controls autoplay loop>
            <source :src="video" type="video/mp4">
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <router-link :to="{name: 'contact'}"
                     class="btn btn-gradient border-light btn-block btn-sm text-uppercase text-light shadow">
          <font-awesome-icon icon="user"/>
          Contacts
        </router-link>
      </div>
      <div class="col-6">
        <a href="https://maps.app.goo.gl/DbswGTn1vDRqXs1A8" target="_blank"
           class="btn btn-gradient border-light btn-block btn-sm text-uppercase text-light shadow">
          <font-awesome-icon icon="map-marker-alt"/>
          Location
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import CountdownBase from "@/views/CountdownBase.vue";

export default {
  name: 'CountdownHome',
  components: {CountdownBase},
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      video: 'https://firebasestorage.googleapis.com/v0/b/api-project-550813444903.appspot.com/o/static%2Frakernas-2024-teaser.mp4?alt=media&token=9b1333c3-8f04-419a-b12b-7e1ee25584f8'
    };
  },
  mounted() {
    document.title = "Countdown";

    let callback = function (e) {
      this.$refs.video.play().then(() => document.removeEventListener('click', callback));
    }.bind(this);

    document.addEventListener('click', callback);
  }
}
</script>