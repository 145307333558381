<template>
  <section>
    <div class="row justify-content-center align-items-center mb-5">
      <div class="col-auto">
        <router-link :to="{name: 'management/index'}">
          <img src="@/assets/images/icon-home.png" alt="Home" style="width: 60px"/>
        </router-link>
      </div>
      <div class="col-auto">
        <button class="btn btn-link text-light" type="button">
          <font-awesome-icon icon="user-check" size="lg" class="ml-2"/>
          <div>{{ totalAll }}</div>
        </button>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <input v-model="form.keyword" class="form-control outline-light text-primary"
               placeholder="Silakan ketik nama peserta..."/>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <template v-if="filterMembers.length">
        <div class="col-10">
          <table class="table text-white">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
            <tr :id="'candidate-' + member.id" :key="member.id" v-for="member in filterMembers">
              <td>{{ member.id }}</td>
              <td>{{ member.name }}</td>
              <td class="text-center align-middle">
                <button @click="remove(member)" class="btn btn-sm btn-link p-0 text-white" type="button"
                        title="Delete Kehadiran">
                  <font-awesome-icon icon="times"/>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </template>
      <div v-else class="col-8">
        <p class="text-center font-weight-bold text-white mb-5">Data tidak ditemukan</p>
      </div>
    </div>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get, remove} from "firebase/database";

export default {
  name: 'ManagementDoorPrize',
  computed: {
    filterMembers() {
      if (this.members.length) {
        return this.members.filter(member => member.name.toLowerCase().includes(this.form.keyword.toLowerCase()))
      } else {
        return [];
      }
    },
    totalAll() {
      return this.members.length;
    },
  },
  methods: {
    remove(member) {
      if (confirm('Delete ' + member.name + ' attend?')) {
        let candidateRef = ref(database, 'candidate/' + member.id);
        remove(candidateRef).then(() => {
          let el = document.getElementById("candidate-" + member.id);
          if (el) {
            el.remove();
          }
          this.$delete(this.members, member.id)
        });
      }
    },
  },
  data() {
    return {
      form: {
        keyword: '',
      },
      members: [],
    };
  },
  mounted() {
    document.title = 'Door Prize';

    let candidateRef = ref(database, 'candidate');
    get(candidateRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.members = Object.values(snapshot.val()).filter(e => e)
      }
    });
  }
}
</script>
