<template>
  <section class="main-layout type-info bg-dark">
    <div class="container-fluid">
      <div class="row justify-content-between align-items-end px-3 mb-5">
        <div class="col-5 col-sm-4">
          <img src="@/assets/images/logo-bumn.png" alt="BUMN" class="img-fluid w-100"/>
        </div>
        <div class="col-5 col-sm-4">
          <img src="@/assets/images/logo-mandiri.png" alt="Bank Mandiri" class="img-fluid w-100"/>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <img src="@/assets/images/banner-event.png" alt="Banner Event" class="img-fluid w-100"/>
        </div>
      </div>
      <div id="menu-button" ref="menu-button" class="row">
        <div class="col-12">
          <button type="button" @click="menuBar = !menuBar" class="btn btn-link text-light">
            <font-awesome-icon icon="bars" size="2x"/>
          </button>
        </div>
      </div>

      <transition name="fade">
        <div id="info-menu" v-show="menuBar" class="row" style="width: 375px">
          <div class="col-12">
            <button type="button" @click="menuBar = false" class="btn btn-link text-light my-4">
              <font-awesome-icon icon="times" size="2x"/>
            </button>
            <div class="row justify-content-center">
              <div class="col-10">
                <div class="item-link">
                  <span class="label">01</span>
                  <a href="#prolog">
                    Prakata CFO
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">02</span>
                  <a href="#about" style="font-size: 1.2rem;">
                    Tentang Rakernas 24
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">03</span>
                  <a href="#rundown">
                    Rundown Acara
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">04</span>
                  <a href="#external-speaker">
                    External Speaker
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">05</span>
                  <a href="#speaker">
                    Keynote Speaker
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">06</span>
                  <a href="#commission-1">
                    Komisi I
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">07</span>
                  <a href="#commission-2">
                    Komisi II
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">08</span>
                  <a href="#commission-3">
                    Komisi III
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">09</span>
                  <a href="#commission-4">
                    Komisi IV
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">10</span>
                  <a href="#entertainment" style="font-size: 0.8rem">
                    Entertaiment Artist & Doorprize
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">11</span>
                  <a href="#floor-plan">
                    Denah
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">12</span>
                  <a href="#sharepoint">
                    Download Portal
                  </a>
                </div>
                <div class="item-link">
                  <span class="label">13</span>
                  <a href="#contact">
                    Contact Person
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div id="content-wrapper" style="overflow-x: hidden">
        <div class="row justify-content-center section" data-aos="fade-up">
          <div class="col-11 video-frame mb-5">
            <div class="embed-responsive embed-responsive-16by9">
              <video class="embed-responsive-item" controls>
                <source :src="video" type="video/mp4">
                Your browser does not support HTML5 video.
              </video>
            </div>
          </div>

          <div class="col-12">
            <h3 class="text-center text-light text-shadow">
              Selamat Datang<br/>
              pada event<br/>
              Rapat Kerja Nasional 2024
            </h3>
          </div>
        </div>

        <div id="prolog" class="row justify-content-center section bg-shadow">
          <div class="col-12 mb-4">
            <img src="@/assets/images/photo-cfo.png" alt="Photo CFO" class="img-fluid w-100" data-aos="flip-down"/>
          </div>
          <div class="col-10 text-light small pb-4" data-aos="fade-down">
            <p class="mb-2">Assalamualaikum Warahmatullahi Wabarakatuh</p>
            <p class="mb-2">Salam sejahtera bagi kita semua, Om Swastiastu, Namo Buddhaya, Salam Kebajikan.</p>
            <p class="mb-2">
              Rekan-rekan Mandirian yang saya banggakan, Rakernas 2024 merupakan key moment bagi seluruh pemimpin di
              Mandiri Group untuk menekankan strategic direction, melihat luasnya potensi di semua lini bisnis,
              menggali,
              mengeruk, dan menguasi potensi tersebut, hingga
              menjadikan target bisnis Mandiri Group lebih aspiratif. Rakernas 2024 juga merupakan tempat bagi kita
              semua
              untuk menyatukan strategi, fokus dan komitmen kita bersama untuk meneruskan kinerja Mandiri Group yang
              solid
              ini agar berkelanjutkan di tahun depan dan 10 tahun berikutnya untuk menuju aspirasi kita menjadi The Best
              Financial Institution in South-East Asia.
            </p>

            <transition name="fade">
              <div v-show="expand">
                <p class="mb-2">
                  Melihat kembali sekilas tahun 2023 ini, Bank Mandiri kembali mencapai kinerja dengan rekor melampaui
                  apa
                  yang telah kita raih selama ini. Mulai dari ROE tertinggi selama 10 tahun terakhir, all-time low NPL,
                  all-time high CASA Ratio, serta apresiasi investor yang tercermin harga saham yang juga mencapai
                  all-time
                  high sebesar Rp6.125 pada tanggal 5 Oktober 2023. Hal ini kembali menjadi pengingat dan bekal bagi
                  kita
                  semua Mandirian bahwa, Mandiri Group memiliki kapasitas untuk meraih mimpi-mimpi besarnya.
                </p>
                <p class="mb-2">
                  Mandirian yang saya banggakan. Melalui kerja keras kita bersama, Mandiri Group kembali berhasil
                  mencatatkan
                  kinerja yang cemerlang.
                </p>
                <p class="mb-2">
                  Sampai dengan Oktober 2023 perolehan laba konsolidasi mencapai sebesar Rp44,0 triliun atau tumbuh
                  29,6%
                  YoY.
                  Peningkatan laba ini ditopang oleh pertumbuhan bisnis Mandiri Group yang konsisten tumbuh diatas
                  industri
                  sepanjang tahun.
                </p>
                <p class="mb-2">
                  Per September 2023, Bank Mandiri kembali mengukuhkan posisi sebagai Bank terbesar dengan raihan total
                  asset
                  konsolidasi mencapai sebesar Rp 2.007 triliun atau tumbuh 9.1% yoy.
                </p>
                <p class="mb-2">
                  Bank Mandiri juga menunjukan agresivitasnya dengan pertumbuhan Kredit konsolidasi sebesar 14,0% sampai
                  dengan Oktober 2023. Lebih tinggi dibandingkan dengan industri dan juga lebih tinggi bila kita
                  bandingkan
                  dengan pertumbuhan rata-rata Bank Mandiri dalam 5 tahun ke belakang.
                </p>
                <p class="mb-2">
                  Pencapaian ini diimbangi dengan kualitas yang terus membaik. NPL Bank Mandiri (bank-only) per Oct-23
                  berada
                  di level 1,15%, yang merupakan NPL terendah sepanjang sejarah Bank Mandiri dan terendah diantara KBMI
                  4
                  lainnya.
                </p>
                <p class="mb-2">
                  Hal ini mendorong peningkatan market share kredit konsolidasi Bank Mandiri secara konsisten semenjak
                  2020
                  dan mencapai sebesar 19,2% per September 2023.
                </p>
                <p class="mb-2">
                  Kinerja yang baik juga tidak lepas dari kontribusi Perusahaan Anak yang telah mencatatkan perolehan
                  Laba
                  Ownership sebesar Rp4,6 Triliun sampai dengan Oktober 2023 dan telah berkontribusi 10,58% dari Laba
                  Konsolidasi Mandiri Group. Pencapaian kinerja ini tentunya terus melanjutkan tren positif dari sisi
                  profitabilitas Bank Mandiri, ROE secara bank only tercatat pada 22,8%. Seperti yang sudah disampaikan
                  diawal, ini merupakan ROE tertinggi yang berhasil kita capai selama 10 tahun terakhir.
                </p>
                <p class="mb-2">
                  Kinerja yang baik ini terus diapresiasi oleh investor, dimana pada tahun ini juga bertepatan dengan
                  tahun
                  ke-20 Bank Mandiri terdaftar di Bursa Efek Indonesia menjadi perusahaan publik. Apresiasi oleh
                  investor
                  tentunya telah tercermin pada peningkatan harga saham dimana Bank Mandiri mencatatkan rekor harga
                  saham
                  all-time high di harga Rp6.125 dengan nilai kapitalisasi pasar yang menembus Rp571.7 Triliun.
                </p>
                <p class="mb-2">
                  Bapak dan Ibu peserta Rakernas Bank Mandiri sekalian.
                  Rakernas Bank Mandiri 2024 mengangkat tema “Achieving Dominance Through Accelerating Growth Across All
                  Potential Sectors”. Artinya pertumbuhan bisnis Mandiri Group harus bertujuan untuk mendominasi market
                  dengan
                  mengeruk seluruh potensi yang dimiliki melalui penguasaan 4 element yang akan dibahas lebih detail
                  dalam
                  komisi Rakernas ini.
                </p>
                <p class="mb-2">
                  Kami secara khusus mengucapkan terima kasih kepada Bapak Erick Thohir, selaku Menteri Badan Usaha
                  Milik
                  Negara (BUMN), yang telah berkenan hadir untuk mengisi sharing session dengan topik “Peran strategis
                  BUMN
                  dan Bank Mandiri dalam mendorong pertumbuhan ekonomi Indonesia secara jangka panjang”.
                </p>
                <p class="mb-2">
                  Bapak Muhamad Chatib Basri selaku Komisaris Utama yang akan memaparkan outlook dari makroekonomi serta
                  perspektif dari dewan komisaris, Bapak Darmawan Junaidi selaku Direktur Utama yang akan memberikan
                  arahan
                  mengenai fokus serta strategi Bank Mandiri di tahun 2024.
                </p>
                <p class="mb-2">
                  Pembahasan strategi 2024 akan disampaikan secara lebih detail pada empat komisi yaitu:
                </p>
                <ol>
                  <li>Accelerate Wholesale Dominance</li>
                  <li>Ecosystem-driven Growth in Retail</li>
                  <li>Digital Platform Optimization; dan</li>
                  <li>Strengthen Group Synergy</li>
                </ol>
                <p class="mb-2">
                  Rekan-rekan Mandirian yang saya banggakan.
                  Berbagai achievement di tahun 2024 ini merupakan buah dari kerja keras kita bersama. Mulai dari
                  peningkatan
                  market share, highest ever profitability, dan berbagai awards yang kita terima. Namun, a true winner
                  never
                  settle.
                </p>
                <p class="mb-2">
                  Artinya, berbagai prestasi yang sudah ditorehkan tahun ini tidak membuat kita lengah, tetapi tahun
                  depan
                  kita harus mencetak prestasi melebihi tahun ini.
                </p>
                <p class="mb-2">
                  Akhir kata, mari kita lanjutkan momentum kinerja yang telah diraih sepanjang tahun ini dan kami harap
                  Rakernas Bank Mandiri 2024 dapat menjadi pondasi dan pijakan baru bagi Mandirian dalam menyelaraskan
                  aspirasi dan berkomitmen untuk bersinergi dalam bekerja, berinovasi, dan mencatatkan kinerja yang
                  sustainable untuk Mandiri Group dalam jangka panjang.
                </p>
                <p class="mb-2">
                  Tidak lupa, kami mengucapkan terima kasih kepada rekan panitia yang telah mempersiapkan acara Rakernas
                  Bank
                  Mandiri 2024 dengan baik.
                </p>
                <p class="mb-2">
                  Wabillahi Taufik Walhidayah
                </p>
                <p class="mb-2">
                  Wassalamu Alaikum Warahmatullahi Wabarakatuh.
                </p>
              </div>
            </transition>

            <button type="button" @click="expand = !expand"
                    class="btn btn-link btn-sm float-right text-light text-shadow">
              {{ expand ? '< Collapse' : 'Read more >' }}
            </button>
          </div>
        </div>

        <div id="about" class="row justify-content-center section bg-shadow" data-aos="fade-up">
          <div class="col-11">
            <img src="@/assets/images/photo-about.png" alt="About" class="img-fluid w-100 mb-4"/>
          </div>
        </div>

        <div id="rundown" class="row justify-content-center section bg-shadow" data-aos="fade-up">
          <div class="col-11">
            <img src="@/assets/images/rundown.png" alt="Rundown" class="img-fluid w-100 mb-4"/>
          </div>
        </div>

        <div id="external-speaker" class="row justify-content-center section bg-shadow" data-aos="fade-up">
          <div class="col-12">
            <h3 class="text-center text-light text-shadow text-uppercase mb-5">
              External Speaker
            </h3>
          </div>

          <div class="col-9">
            <img src="@/assets/images/photo-external-speaker.png" alt="External Speaker" class="img-fluid w-100 mb-4"/>
          </div>
        </div>

        <div id="speaker" class="row justify-content-center section bg-shadow" data-aos="zoom-in-down">
          <div class="col-12 text-center" data-aos="fade-in">
            <h3 class="text-center text-light text-shadow text-uppercase mb-5">
              Keynote Speaker
            </h3>
          </div>

          <div class="col-12">
            <img src="@/assets/images/photo-keynote-speaker.png" alt="Keynote Speaker" class="img-fluid w-100 mb-4"/>
          </div>
        </div>

        <div :id="'commission-' + n" v-for="n in 4" class="row justify-content-center section"
             data-aos="zoom-out">
          <div class="col-11 py-4 bg-shadow">
            <img :src="require('@/assets/images/photo-commission-' + n +'.png')" :alt="'Commission ' + n"
                 class="img-fluid w-100"/>

            <div class="row justify-content-end" v-if="fileCommissions[n - 1]">
              <div class="col-auto mr-3">
                <a :href="fileCommissions[n - 1]"
                   target="_blank"
                   class="btn btn-gradient border btn-sm text-uppercase text-light shadow px-4 my-3 ml-auto">
                  <font-awesome-icon icon="file-download"/>
                  Materi
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="entertainment" class="row justify-content-center section bg-shadow" data-aos="zoom-out-up">
          <div class="col-12" data-aos="zoom-in-up">
            <img src="@/assets/images/photo-entertainment.png" class="img-fluid w-100 mb-4"/>
          </div>
        </div>

        <div id="floor-plan" class="row justify-content-center section bg-shadow" data-aos="fade-up">
          <div class="col-10" data-aos="zoom-in-up">
            <img src="@/assets/images/floor-plan.png" class="img-fluid w-100 mb-5"/>
          </div>
        </div>

        <div id="sharepoint" class="row justify-content-center section" data-aos="zoom-out-down">
          <div class="col-12">
            <h3 class="text-center text-light text-shadow text-uppercase mb-5">
              Sharepoint Portal
            </h3>
          </div>
          <div class="col-11">
            <div class="row justify-content-around">
              <div data-aos="zoom-in-down" v-for="file in files"
                   class="col-auto video-frame text-light text-center mb-4 p-2">
                <div class="sharepoint-file bg-gradient">
                  <a :href="file.url"
                     target="_blank" class="font-weight-normal-bold text-light text-uppercase px-2">
                    {{ file.name }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="contact" class="section" data-aos="fade-down">
          <Contact/>
        </div>
      </div>

      <router-link :to="{name: 'home'}"
                   class="btn btn-gradient border btn-sm text-uppercase text-light shadow main-menu-button">
        <font-awesome-icon icon="bars"/>
        Main Menu
      </router-link>

    </div>
  </section>
</template>

<script>
import Contact from "@/views/ContactBase.vue";

export default {
  name: 'Information',
  components: {Contact},
  data() {
    return {
      linkElements: [],
      fileCommissions: [
        'https://drive.google.com/drive/folders/1OqLPz_jNIY2yjJJYDibVTLelT8vQA7s2',
        'https://drive.google.com/drive/folders/1TX8LZfeZgwlQfbAF1kFIHo-6gnm925BN',
        'https://drive.google.com/drive/folders/1OCO9GqwNhZWRsGo9slUiPjqQ2u5BvSgZ',
        'https://drive.google.com/drive/folders/1myGK19D-_P5nXUenEiLqWbnq0bv5rwsj',
      ],
      menuBar: false,
      expand: false,
      video: 'https://firebasestorage.googleapis.com/v0/b/api-project-550813444903.appspot.com/o/static%2Frakernas-2024-teaser.mp4?alt=media&token=9b1333c3-8f04-419a-b12b-7e1ee25584f8',
      files: [
        {name: 'Direktur Utama', url: 'https://drive.google.com/drive/folders/1Kz7Cj4XFxsOa0wdIvmfnF_uM2x1WEycR'},
        {name: 'Direktur Keuangan', url: 'https://drive.google.com/drive/folders/1PxcDvQJZMtdlwSq_8bgjHx5xHHGSLu5w'},
        {name: 'Direktur Kepatuhan & SDM', url: 'https://drive.google.com/drive/folders/1-DCrLn96SJak54TeGNirP4XwXZ7Unj9h'},
        {name: 'External Speaker', url: 'https://drive.google.com/drive/folders/1Fr1Kwg250h-_at1xoC9aAMo0JGANmzU3'},
        {name: 'Komisaris', url: 'https://drive.google.com/drive/folders/13x8Uqe18WIxD4UalC5V1WZsTTD4vbz8l'},
        {name: 'Komisi 1', url: 'https://drive.google.com/drive/folders/1OqLPz_jNIY2yjJJYDibVTLelT8vQA7s2'},
        {name: 'Komisi 2', url: 'https://drive.google.com/drive/folders/1TX8LZfeZgwlQfbAF1kFIHo-6gnm925BN'},
        {name: 'Komisi 3', url: 'https://drive.google.com/drive/folders/1OCO9GqwNhZWRsGo9slUiPjqQ2u5BvSgZ'},
        {name: 'Komisi 4', url: 'https://drive.google.com/drive/folders/1myGK19D-_P5nXUenEiLqWbnq0bv5rwsj'},
        {name: 'Booklet', url: 'https://drive.google.com/drive/folders/1BIMSBseZ2hM_91A-Y8ig3EPHpzij5rLO'},
        {name: 'Dokumentasi Foto', url: 'https://drive.google.com/drive/folders/1-3-mtN-AM9XlJn0YkcqojTNcuGBShGHc'},
      ]
    };
  },
  mounted() {
    document.title = "Information";

    this.$refs['menu-button'].addEventListener('focusout', () => this.menuBar = false)
    this.linkElements = document.querySelectorAll('#info-menu a');
    this.linkElements.forEach(el => {
      el.addEventListener('click', () => this.menuBar = false)
    });
  },
  beforeDestroy() {
    this.$refs['menu-button'].removeEventListener('focusout', null);
    this.linkElements.forEach(el => {
      el.removeEventListener('click')
    });
  }
}
</script>

<style scoped>
.item-link > .label {
  color: #9df4ff;
  font-family: 'Nasalization Rg';
  vertical-align: top;
  font-weight: bold;
  width: 1.4rem;
  font-size: 0.65rem;
  display: inline-block;
  margin-top: 8px;
}

.item-link > a {
  padding: 4px 0;
  display: inline-block;
  color: #ffffff;
  font-size: 1.4rem;
  font-family: 'GothamBold';
  text-transform: uppercase;
}

#content-wrapper > .section {
  padding-top: 2rem;
  margin-bottom: 10rem;
}

#menu-button {
  position: -webkit-sticky;
  position: sticky;
  top: 1.5rem;
  z-index: 1;
}

#info-menu {
  position: fixed;
  z-index: 2;
  height: 100vh;
  top: 0;
  background-color: #0a3c76;
}

.sharepoint-file {
  width: 200px;
  height: 110px;
  line-height: 110px;
}

.sharepoint-file a {
  vertical-align: middle;
  display: inline-block;
  font-size: 20px;
  line-height: 1.5;
}

.bg-shadow {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>