<template>
  <section>
    <div class="row justify-content-center">
      <div class="col-8">
        <h1>
          <div class="text-white">{{ title }}</div>
          <code v-if="code">404</code>
        </h1>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Error',
  props: {
    title: {
      type: String
    },
    code: {
      type: Number
    },
  },
  created() {
    if (!this.title) {
      this.$router.replace({name: 'index'});
    }
    let errorCode = this.code ? ('Error ' + this.code + ' ') : '';

    document.title = errorCode + this.title + ' - ' + process.env.VUE_APP_NAME;
  }
}
</script>
