<template>
  <section>
    <div class="row justify-content-center mb-4">
      <div class="col-10">
        <input v-model="form.keyword" class="form-control text-light text-uppercase text-center bg-gradient shadow"
               placeholder="Ketik nama"/>
      </div>
    </div>
    <div v-if="form.keyword" class="row justify-content-center mb-5">
      <template v-if="filterMembers.length">
        <div class="col-10 bg-gradient px-5 py-3 shadow round-lg border panel-card mb-5">
          <button @click="selectedMember = member" :key="member.id" v-for="member in filterMembers"
                  class="btn btn-link text-light btn-block text-uppercase">
            {{ member.name }}
            <div class="small">{{ member.nip }}</div>
          </button>
        </div>
      </template>
      <div v-else class="col-8">
        <p class="text-center font-weight-bold text-white mb-5">Data tidak ditemukan</p>
      </div>
    </div>

    <b-modal v-if="selectedMember" centered :hide-header="true" @ok="submit" @cancel="selectedMember = null"
             content-class="background p-4 text-light" v-model="modal.attendance"
             footer-class="border-0 justify-content-center">
      <h4 class="text-center text-uppercase mb-4" v-html="event.name"></h4>
      <h5 class="text-center">
        Selamat Datang, {{ selectedMember.name }} <br/>
        <span v-html="getContent('confirmation')"></span>
      </h5>

      <template #modal-footer="{ ok, cancel }">
        <div class="col-5">
          <button @click="cancel()" type="button"
                  class="btn btn-gradient btn-sm border shadow text-light btn-block text-uppercase">
            Cancel
          </button>
        </div>
        <div class="col-5">
          <button @click="ok()" type="button"
                  class="btn btn-gradient btn-sm border shadow text-light btn-block text-uppercase">
            {{ getContent('button') }}
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal v-if="event" :title="event.name" centered :hide-header="true" :hide-footer="true" :static="true"
             v-model="modal.confirmation"
             @hide="selectedMember = null; form.keyword = ''" content-class="background text-light" body-class="px-0">

      <template v-if="event.layout">
        <div class="row justify-content-center my-3">
          <div class="col-4">
            <img src="@/assets/images/banner-event.png" alt="Banner Event" class="img-fluid w-100"/>
          </div>
        </div>
        <div class="row justify-content-center mb-5 text-light px-4">
          <div class="col-5">
            <h6 class="text-shadow mb-0 small"><strong>Kamis</strong></h6>
            <p class="text-shadow-sm h6 small">14 Desember 2023</p>
          </div>
          <div class="col-6">
            <h6 class="text-shadow mb-0 small"><strong>Venue</strong></h6>
            <p class="text-shadow-sm h6 small">
              Nawasena<br/>
              Kawasan Bank Mandiri Wijayakusuma
            </p>
          </div>
        </div>

        <div class="row justify-content-center mb-4" v-if="selectedMember">
          <div class="col-11">
            <h4 class="text-shadow text-center mb-2">Kursi <strong>{{ selectedMember.number }}</strong></h4>
            <hr class="border-light shadow my-0" style="width: 50%"/>
            <h4 class="text-shadow text-center my-2">
              <strong>{{ selectedMember.name }}</strong>
            </h4>
          </div>
        </div>

        <div class="row">
          <div class="col bg-gradient-layout py-3">
            <inline-svg :src="'/event/' + event.layout" class="w-100 h-100"/>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row justify-content-center">
          <div class="col-10">
            <h5 class="text-center mt-3" v-html="getContent(!hasRedeem ? 'success' : 'failed')"></h5>
          </div>
        </div>
      </template>

      <div class="row justify-content-center mt-4">
        <div class="col-7">
          <router-link :to="{name: 'information'}"
                       class="btn btn-gradient btn-sm border shadow text-light btn-block text-uppercase mb-3">
            Information
          </router-link>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get, set} from "firebase/database";
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'Search',
  components: {
    InlineSvg,
  },
  props: {
    event: {
      default: null,
      required: false
    }
  },
  watch: {
    selectedMember(member) {
      if (member) {
        let eventGuestRef = ref(database, 'event-guest/' + this.event.id + '/' + member.id);
        get(eventGuestRef).then((snapshot) => {
          let element = document.getElementById('number-' + member.number);
          if (element) {
            element.classList.add('dim');
          }

          if (!snapshot.exists()) {
            this.modal.attendance = true;
          } else {
            this.hasRedeem = true;
            this.modal.confirmation = true;

            let attend = snapshot.val();
            console.log('Already check in at ' + this.$moment(attend.datetime, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY - HH.mm'));
          }
        })
      } else {
        let elements = document.querySelectorAll('rect[id^="number-"].dim');
        elements.forEach(function (element) {
          element.classList.remove('dim');
        })
        this.hasRedeem = false;
      }
    }
  },
  computed: {
    filterMembers() {
      let filters = [];
      if (this.members.length) {
        filters = this.members.filter(member => {
          let included = member.name.toLowerCase().includes(this.form.keyword.toLowerCase())
          included |= member.nip ? member.nip.includes(this.form.keyword) : false
          return included;
        })
      }
      return filters.slice(0, 3);
    }
  },
  methods: {
    submit() {
      let eventGuestRef = ref(database, 'event-guest/' + this.event.id + '/' + this.selectedMember.id);
      set(eventGuestRef, {datetime: this.$moment().format('YYYY-MM-DD HH:mm:ss')}).then(() => this.modal.confirmation = true);
    },
    getContent(index) {
      return this.contentOptions[this.event.id][index];
    },
  },
  data() {
    return {
      form: {
        keyword: '',
        attendance: {}
      },
      members: [],
      modal: {
        attendance: false,
        confirmation: false
      },
      selectedMember: null,
      hasRedeem: false,
      contentOptions: {
        1: {
          button: "Check-In",
          confirmation: "di acara <br/> Rapat Kerja Nasional 2023",
          success: "",
          failed: "",
        },
        2: {
          button: "Redeem",
          confirmation: 'untuk<br /> Pengambilan Buku',
          success: "Pengambilan buku telah berhasil, terima kasih banyak atas partisipasinya.",
          failed: "Mohon maaf, kupon buku sudah terpakai.<br />Silakan hubungi Panitia.",
        }
      }
    };
  },
  mounted() {
    let guestRef = ref(database, 'guest');
    get(guestRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.members = Object.values(snapshot.val()).filter(e => e)
      }
    });
  }
}
</script>

<style scoped>
.panel-card {
  border-radius: 2rem;
  border-width: 2px;
}

.bg-gradient-layout {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(42, 150, 209, 1) 50%, rgba(255, 255, 255, 0) 100%);
}
</style>