<template>
  <section class="main-layout type-2 bg-dark">
    <div class="container-fluid">
      <div class="row justify-content-between align-items-end px-3 mb-2">
        <div class="col-5 col-sm-4 col-md-3 col-lg-2">
          <img src="@/assets/images/logo-bumn.png" alt="BUMN" class="img-fluid w-100"/>
        </div>
        <div class="col-5 col-sm-4 col-md-3 col-lg-2">
          <img src="@/assets/images/logo-mandiri.png" alt="Bank Mandiri" class="img-fluid w-100"/>
        </div>
      </div>
      <div class="row justify-content-center px-3">
        <div class="col-4">
          <img src="@/assets/images/banner-door-prize.png" alt="Banner" class="img-fluid w-100"/>
        </div>
      </div>
      <div class="row justify-content-center" style="margin: 8vh 0">
        <div class="col-9 text-center bg-light border border-light shadow px-5" style="border-radius: 5rem">
          <h1 class="display-5 text-info text-truncate text-shadow py-3 mb-0" :class="{glow: !process}">
            {{ selectedMember ? selectedMember.name : '&nbsp;' }}
          </h1>
        </div>
      </div>
      <div class="row justify-content-center mb-5">
        <div class="col-5 col-md-3 text-center">
          <button @click="randomize()" type="button"
                  class="btn btn-block btn-info btn-lg border shadow"
                  :class="{disabled: process || members.length === 0}">
            start
          </button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="row">
            <div class="col" v-for="n in Math.ceil(winners.length / chuckSize)">
              <ol :start="((n - 1) * chuckSize) + 1" class="h3 text-info text-shadow">
                <li v-for="winner in winners.slice(((n - 1) * chuckSize), chuckSize * n)">
                  {{ winner.name }}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal hide-header-close static v-if="selectedMember" v-model="modal.confirmation" centered>
      <template #modal-title>
        <h5>
          Congratulation!
        </h5>
      </template>
      <div class="text-center">
        <h2>{{ selectedMember.name }}</h2>
      </div>
      <template #modal-footer="{ ok }">
        <div class="row">
          <div class="col">
            <button @click="remove(selectedMember)" type="button" class="btn btn-secondary">
              Hapus
            </button>
          </div>
          <div class="col">
            <button @click="ok()" type="button" class="btn btn-info">
              Konfirmasi
            </button>
          </div>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {get, ref, remove} from "firebase/database";

export default {
  name: 'DoorPrize',
  methods: {
    random() {
      return Math.floor(Math.random() * this.members.length);
    },
    randomIndex() {
      let index = this.random();
      let member = this.members[index];
      while (member !== undefined && this.excludeNames[member.name] !== undefined) {
        index = this.random();
      }
      return index;
    },
    load() {
      let guestRef = ref(database, 'candidate');
      get(guestRef).then((snapshot) => {
        if (snapshot.exists()) {
          this.members = Object.values(snapshot.val()).filter(e => e)
        }
      });
    },
    randomize() {
      if (!this.members.length) {
        alert('There is no member attend for register');
        return;
      }

      this.process = true;
      let timeout = 50;
      let time = 0;

      let randomizeMember = () => {
        this.selectedIndex = this.randomIndex();

        this.selectedMember = this.members[this.selectedIndex];
        time += timeout;
        if (time < this.duration) {
          let remainingTime = this.duration - time;
          if (remainingTime < 5000) {
            timeout = 100;
          } else if (remainingTime < 3000) {
            timeout = 500;
          }
          setTimeout(randomizeMember, timeout);
        } else {
          this.excludeNames[this.selectedMember.name] = this.selectedIndex;
          this.winners.push(this.selectedMember);
          this.process = false;
          this.modal.confirmation = true;
        }
      }

      setTimeout(randomizeMember, timeout)
    },
    remove(currentMember) {
      this.members = this.members.filter(member => {
        if (member.name === currentMember.name) {
          let eventGuestRef = ref(database, 'candidate/' + member.id);
          remove(eventGuestRef);
          return false;
        }
        return true;
      });
      this.modal.confirmation = false
    },
  },
  data() {
    return {
      chuckSize: 3,
      process: false,
      members: [],
      winners: [],
      excludeNames: [],
      selectedMember: null,
      selectedIndex: null,
      duration: 5000,
      modal: {
        confirmation: false
      }
    };
  },
  mounted() {
    this.load();
  }
}
</script>

<style scoped>
.main-layout {
  background-size: cover;
  max-width: inherit;
}

button.disabled {
  pointer-events: none;
}

.glow {
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #9a6b00, 0 0 40px #d27900;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #e39700, 0 0 40px #d3a50c, 0 0 50px #ffb765;
  }
}
</style>