<template>
  <section>
    <div class="row justify-content-center pt-5">
      <div class="col-10">
        <router-link :key="event.id" v-for="event in events" :to="{name: 'event', params: {id: event.id}}"
                     v-html="event.name" :style="{'line-height': parseInt(event.id) === 1 ? 0.8 : 'inherit'}"
                     class="btn btn-gradient border-light btn-block text-uppercase text-light mb-4 shadow">
        </router-link>
        <router-link :to="{name: 'information'}"
                     class="btn btn-gradient border-light btn-block text-uppercase text-light shadow">
          Information
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get} from "firebase/database";

export default {
  name: 'Home',
  data() {
    return {
      events: [],
      title: process.env.VUE_APP_TITLE
    };
  },
  mounted() {
    let eventRef = ref(database, 'event');
    get(eventRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.events = snapshot.val().filter(e => e && e.active)
      }
    });

    document.title = "Home";
  }
}
</script>