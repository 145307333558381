<template>
  <section>
    <div class="row justify-content-between align-items-end px-3 mb-5">
      <div class="col-5 col-sm-4">
        <img src="@/assets/images/logo-bumn.png" alt="BUMN" class="img-fluid w-100"/>
      </div>
      <div class="col-5 col-sm-4">
        <img src="@/assets/images/logo-mandiri.png" alt="Bank Mandiri" class="img-fluid w-100"/>
      </div>
    </div>
    <router-view></router-view>
  </section>
</template>

<script>

export default {
  name: "main-layout",
};
</script>