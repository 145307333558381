<template>
  <section>
    <div class="row justify-content-center mb-5">
      <div class="col-10 text-center">
        <countdown id="countdown" class="display-1" :time="remainingTime" @end="() => $router.push({name: 'index'})">
          <template slot-scope="props">
            {{ padZero(props.totalHours, 2) }}:{{ padZero(props.minutes, 2) }}:{{ padZero(props.seconds, 2) }}
          </template>
        </countdown>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'CountdownBase',
  methods: {
    padZero(number, digit) {
      let pad = digit - number.toString().length;
      if (pad < 0) {
        pad = 0;
      }
      return '0'.repeat(pad) + number;
    }
  },
  data() {
    let currentDate = new Date();
    return {
      remainingTime: new Date(process.env.VUE_APP_DATETIME_LAUNCH).getTime() - currentDate.getTime(),
    };
  },
}
</script>

<style scoped>
#countdown {
  font-family: 'Nasalization Rg';
  color: #ffffff;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #9a6b00, 0 0 40px #d27900;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #e39700, 0 0 40px #d3a50c, 0 0 50px #ffb765;
  }
}
</style>