import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isAuthenticated: false,
    },
    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
    },
    mutations: {
        authenticate(state, payload) {
            state.isAuthenticated = payload.isAuthenticated;
        },
    },
    actions: {
        login(context, payload) {
            let status = window.btoa(payload) === process.env.VUE_APP_PASSCODE;
            if (status) {
                context.commit('authenticate', {isAuthenticated: true});
            }
            return {status: status}
        },
    },
    modules: {}
})
