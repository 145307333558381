import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '../components/layouts/Base'
import MainLayout from '../components/layouts/Main'
import SubMainLayout from '../components/layouts/SubMain'
import ManagementLayout from '../components/layouts/Management'
import Countdown from '../views/Countdown'
import Home from "../views/Home";
import Event from '../views/Event'
import Contact from '../views/Contact'
import Information from '../views/Information'
import DoorPrize from '../views/DoorPrize'
import Launch from '../views/Launch'
import ManagementLogin from '../views/management/Login'
import ManagementIndex from '../views/management/Index'
import ManagementEvent from '../views/management/Event'
import ManagementDoorPrize from '../views/management/DoorPrize'
import Error from '../views/Error'

import Store from '@/store/index.js';

Vue.use(VueRouter)

const routes = [
    {path: '/door-prize', name: 'door-prize', component: DoorPrize},
    {path: '/information', name: 'information', component: Information},
    {
        path: '', component: BaseLayout, children: [
            {
                path: '', component: MainLayout, children: [
                    {path: '/countdown', name: 'countdown', component: Countdown},
                    {path: '/contact', name: 'contact', component: Contact},
                    {path: '/event/:id(\\d+)', name: 'event', component: Event, props: true},
                    {
                        path: '', component: SubMainLayout, children: [
                            {
                                path: '/', name: 'index', component: Home, beforeEnter: (to, from, next) => {
                                    let remainingTime = new Date(process.env.VUE_APP_DATETIME_LAUNCH).getTime() - (new Date()).getTime();
                                    if (remainingTime > 0) {
                                        next({name: 'countdown', replace: true});
                                    } else {
                                        next();
                                    }
                                }
                            },
                            {path: '/home', name: 'home', component: Home},
                            {path: '/launch', name: 'launch', component: Launch},
                        ],
                    },

                    /**
                     * Error Page
                     */
                    {path: "/error", name: "error", component: Error, props: true},

                    /**
                     * Management
                     */
                    {path: '/management/login', name: 'management/login', component: ManagementLogin},
                    {
                        path: '', component: ManagementLayout, children: [
                            {
                                path: '/management',
                                name: 'management/index',
                                component: ManagementIndex,
                            },
                            {
                                path: '/management/event/:id(\\d+)',
                                name: 'management/event',
                                component: ManagementEvent,
                                props: true,
                            },
                            {
                                path: '/management/door-prize',
                                name: 'management/door-prize',
                                component: ManagementDoorPrize,
                            },
                        ],
                        beforeEnter: authenticate
                    },
                ]
            },
            /**
             * Catch undefined route to error page
             */
            {path: "*", redirect: {name: 'error', params: {code: 404, title: "Page Not Found"}}},
        ],
    }
]

function authenticate(to, from, next) {
    if (!Store.getters.isAuthenticated) {
        next({name: 'management/login', replace: true});
    } else {
        next();
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
