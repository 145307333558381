<template>
  <section>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <h3 class="text-light text-center text-uppercase">Contact Person</h3>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-10 col-sm-8">
        <div class="row justify-content-around">
          <div class="col-auto video-frame mb-3" v-for="contact in contacts" data-aos="zoom-in">
            <div class="panel-card bg-gradient py-2">
              <h6 class="text-light text-center text-uppercase mb-0 small">{{ contact.title }}</h6>
              <p class="text-light text-center text-uppercase font-weight-normal-bold h4 mb-1">{{ contact.name }}</p>
              <a class="text-light text-center text-uppercase mb-0 small d-block" :href="'tel:' + contact.phoneNumber">
                {{ contact.phoneNumber }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ContactBase',
  data() {
    return {
      contacts: [
        {title: "Ketua", name: "Satria", phoneNumber: "0821 2708 1987"},
        {title: "Sekretaris", name: "Nurul", phoneNumber: "0878 7798 1747"},
        {title: "Transportasi", name: "Steven", phoneNumber: "0811 8800 314"},
        {title: "Akomodasi", name: "Fallendi", phoneNumber: "0856 8358 044"},
        {title: "Materi Komisi", name: "Wulan", phoneNumber: "0812 9659 1451"},
        {title: "Acara", name: "Angel", phoneNumber: "0815 1917 9868"},
      ]
    };
  },
}
</script>

<style scoped>
.video-frame {
  padding: 7.5px;
}
.video-frame > .panel-card {
  width: 150px;
  height: 80px;
}
</style>