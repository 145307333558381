<template>
  <section>
    <div class="row justify-content-center mb-3">
      <div class="col-12">
        <h3 class="text-center text-light text-shadow text-uppercase mb-4">
          Bank Mandiri go to 2024
        </h3>
      </div>
    </div>
    <template v-if="!commit">
      <div class="row justify-content-center mb-4">
        <div class="col-10">
          <input ref="keyword" v-model="form.keyword"
                 class="form-control text-light text-uppercase text-center bg-gradient shadow"
                 placeholder="Ketik nama"/>
        </div>
      </div>

      <div class="position-relative">
        <div v-if="selection" class="row justify-content-center">
          <template v-if="filterMembers.length">
            <div class="col-10 bg-gradient px-5 py-3 shadow round-lg border panel-card mb-5 position-absolute"
                 style="top: 0; z-index: 1">
              <button @click="selectedName = member.name" :key="member.id" v-for="member in filterMembers"
                      class="btn btn-link text-light btn-block text-uppercase">
                {{ member.name }}
              </button>
            </div>
          </template>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-7 position-relative">
          <button @click="commit = true" class="btn btn-link">
            <img src="@/assets/images/launch-button.png" alt="Button" class="img-fluid w-100 dim mt-3"
                 style="border-radius: 50rem;"/>
            <p class="position-absolute centered">{{ selectedName }}</p>
          </button>
        </div>
      </div>
    </template>
    <div v-else class="row justify-content-center">
      <div class="col-7 mt-3">
        <img src="@/assets/images/launch-end.png" alt="Button" class="img-fluid w-100"/>
      </div>
    </div>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get} from "firebase/database";

export default {
  name: 'Launch',
  watch: {
    selectedName() {
      this.form.keyword = '';
      this.selection = false;
    }
  },
  computed: {
    filterMembers() {
      return this.members.length ?
          this.members.filter(member =>
              member.name.toLowerCase().includes(this.form.keyword.toLowerCase()))
              .slice(0, 3) :
          []
    }
  },
  data() {
    return {
      form: {keyword: ''},
      members: [],
      selectedName: '',
      selection: false,
      commit: false,
      title: process.env.VUE_APP_TITLE,
    };
  },
  mounted() {
    let guestRef = ref(database, 'guest');
    get(guestRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.members = Object.values(snapshot.val()).filter(e => e)
      }
    });

    document.title = "Launch";

    this.$refs.keyword.addEventListener('focusin', () => this.selection = true)
  },
  beforeDestroy() {
    this.$refs.keyword.removeEventListener('focusin', null);
  }
}
</script>

<style scoped>
.position-relative .centered.position-absolute {
  width: 150px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.panel-card {
  border-radius: 2rem;
  border-width: 2px;
}

.dim {
  animation: dim 3s infinite;
}

@keyframes dim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>