import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue';
import VueMoment from 'vue-moment';
import moment from 'moment';
import AOS from 'aos'
import 'aos/dist/aos.css'

moment.locale('id')

import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faExternalLinkAlt,
    faTimes,
    faFileDownload,
    faUserCheck,
    faTimesCircle,
    faUser,
    faInfoCircle,
    faMapMarkerAlt,
    faBars,
    faDownload,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import VueCountdown from '@chenfengyuan/vue-countdown';

import './assets/styles/app.scss'

library.add(faExternalLinkAlt, faTimes, faFileDownload, faUserCheck, faTimesCircle, faUser, faInfoCircle, faMapMarkerAlt, faBars, faDownload);

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(VueMoment, {moment});

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component(VueCountdown.name, VueCountdown);

new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    },
    mounted() {
        AOS.init({
            duration: 1400
        })
    },
}).$mount('#app')
