<template>
  <section>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <img src="@/assets/images/banner-contact.png" alt="Banner Contact" class="img-fluid w-100"/>
      </div>
    </div>

    <ContactBase/>

    <div class="row justify-content-center">
      <div class="col-8">
        <router-link :to="{name: 'index'}"
                     class="btn btn-gradient border-light btn-block btn-sm text-uppercase text-light shadow">
          <font-awesome-icon icon="info-circle"/>
          Back
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import ContactBase from "@/views/ContactBase.vue";

export default {
  name: 'Contact',
  components: {ContactBase},
  mounted() {
    document.title = "Contact Person";
  }
}
</script>