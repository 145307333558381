<template>
  <section>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <img src="@/assets/images/banner-event.png" alt="Banner Contact" class="img-fluid w-100"/>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <h3 class="text-light text-center text-uppercase" v-if="event" v-html="event.name"></h3>
      </div>
    </div>

    <Search v-if="event" :event="event"/>

    <router-link :to="{name: 'home'}"
                 class="btn btn-gradient border btn-sm text-uppercase text-light shadow main-menu-button">
      <font-awesome-icon icon="bars"/>
      Main Menu
    </router-link>
  </section>
</template>

<script>
import Search from "@/components/Search.vue";
import {database} from "@/service/firebase";
import {get, ref} from "firebase/database";

export default {
  name: 'Event',
  props: {
    id: {
      required: true
    }
  },
  components: {
    Search
  },
  data() {
    return {
      event: null
    };
  },
  mounted() {
    let eventRef = ref(database, 'event/' + this.id);
    get(eventRef).then((snapshot) => {
      this.event = snapshot.val()
      if (snapshot.exists() && this.event) {
        document.title = this.event.name.replace(/<[^>]*>|&nbsp;?/gm, ' ');
      } else {
        this.$router.replace({
          name: "error", params: {code: 404, title: "Event not found"}
        })
      }
    });
  }
}
</script>
