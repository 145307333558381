<template>
  <section>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <img src="@/assets/images/banner-home.png" alt="Home" class="img-fluid w-100"/>
      </div>
    </div>
    <router-view></router-view>
  </section>
</template>

<script>

export default {
  name: "main-sub-layout",
};
</script>